import React from "react";
import Layout from "../components/Layout";
import Chart from "../containers/RadarChartContainer";
import CategoriesPanel from "../containers/CategoriesPanelContainer";
import PersonPanel from "../containers/PersonPanelContainer";
import CompareToRoleSelector from "../containers/CompareToRoleSelectorContainer";
import Footer from "../components/Footer";
import logo from '../img/logo.png';
import Intro from '../components/Intro';

export default props => (
  <Layout>
    <header className="header">
      <div className="header__container">
        <h1>
          <img className="header__logo" src={logo} alt="Opia" />
          <span className="header__title">Technical Progression Framework</span>
        </h1>
      </div>
    </header>

    <Intro />

    <section className="l-chart">
      <div className="l-chart__config">
        <div>
          <div className="l-chart__person">
            <PersonPanel
              name={props.status.name}
              role={props.roles.reduce(
                (accum, currentRole) =>
                  Object.assign(
                    accum,
                    currentRole.key === props.status.roleKey ? currentRole : {}
                  ),
                {}
              )}
              tags={props.status.tags}
            />
          </div>

          <CompareToRoleSelector roles={props.roles} />
        </div>
      </div>
      <div className="l-chart__display">
        <Chart colors={props.colors} />
      </div>
    </section>

    <CategoriesPanel categories={props.categories} />

    <Footer />
  </Layout>
);
