import React from 'react';

export default props => (
  <section className="l-intro">
    <div className="l-intro__container">
      <div className="intro">
        <div className="intro__header">
          <h2 className="intro__title">
            Welcome
          </h2>
        </div>
        <div className="intro__content">
          <p className="intro__paragraph">
            Welcome to the Opia Technical Progression Framework! This purpose of this tool is to
            help you figure out how to progress technically, and to provide you with the
            resources to do so. It will serve as the core that further progression initiatives
            will launch from; personal progression plans, workshops, hackathons, tech socials
            and much&nbsp;more.
          </p>

          <p className="intro__paragraph">
            It is based around 11 categories, each of which represent a core set of ideas every
            developer would benefit from understanding. We have elevated the <em>why</em> over the <em>how</em>,
            valuing ideas over technology. While languages are frameworks are important,
            understanding the ideas behind them enables you to make better decisions with
            confidence.
          </p>
        </div>
      </div>
    </div>
  </section>
);
