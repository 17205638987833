import React from "react";
import PropTypes from "prop-types";

class CompareToRoleSelector extends React.Component {
  constructor(props) {
    super(props);
    this.roles = props.roles;
    this.compareTo = props.compareTo;
    this.dispatchSelectComparationRole = props.selectComparationRole;
  }

  selectComparationRole = e =>
    this.dispatchSelectComparationRole(e.target.value);

  render = () => (
    <label className="role-selector">
      <div className="role-selector__label">Compare to{" "}</div>
      <div className="select">
        <select
          className="select__input"
          onChange={this.selectComparationRole.bind(this)}
          defaultValue={this.compareTo?.key}
        >
          <option>Select a role</option>
          {this.roles.map(role => (
            <option key={role.key} value={role.key}>
              {role.name}
            </option>
          ))}
        </select>
      </div>
    </label>
  );
}

CompareToRoleSelector.propTypes = {
  compareTo: PropTypes.object,
  roles: PropTypes.arrayOf(Object).isRequired,
  selectComparationRole: PropTypes.func.isRequired
};

export default CompareToRoleSelector;
