import React from "react";
import PropTypes from "prop-types";
import Markdown from "./Markdown";

class CategoriesPanel extends React.Component {
  constructor(props) {
    super(props);
    this.categories = props.categories;
    this.state = { selectedCategory: props.categories[0], selectedLevel: 0 };
    this.dispatchSelectCategoryLevel = props.selectCategoryLevel;
  }

  getLevel = category =>
    this.props.selectedLevels[category.key]
      ? this.props.selectedLevels[category.key]
      : 0;

  getKPIs = () =>
    this.state.selectedCategory.getKpisByLevel(
      this.getLevel(this.state.selectedCategory)
    );

  getKPIsPercentage = () =>
    (this.getLevel(this.state.selectedCategory) * 100) /
    this.state.selectedCategory.getLevels().length;

  selectCategory = (category, e) => {
    e.preventDefault();
    this.setState({
      selectedCategory: category,
      selectedLevel: this.getLevel(category)
    });
  };

  selectLevel = (level, category, e) => {
    e.preventDefault();
    this.setState({ selectedLevel: level });
    this.dispatchSelectCategoryLevel(category, level);
  };

  render = () => (
    <section id="category-panel" className="l-categories">
      <div className="l-categories__container">
        <div className="l-categories__nav">
          <ul className="list-nav">
            {this.categories.map(category => (
              <li
                key={category.key}
                className={"list-nav__item" + (this.state.selectedCategory.key === category.key
                  ? " is-active"
                  : "")}
              >
                <a
                  className="list-nav__link"
                  href="#category-panel"
                  onClick={this.selectCategory.bind(this, category)}
                >
                  {category.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="l-categories__content">
          <div className="l-categories__levels">
            <ul className="radio-nav">
              {this.state.selectedCategory.getLevels().map(level => (
                <li key={level} className={"radio-nav__item " + (this.getLevel(this.state.selectedCategory) >= level
                  ? " is-active "
                  : "") + (this.getLevel(this.state.selectedCategory) === level
                  ? " radio-nav__item--last "
                  : "")}>
                  <a
                    href="#category-panel"
                    className="radio-nav__link"
                    onClick={this.selectLevel.bind(
                      this,
                      level,
                      this.state.selectedCategory
                    )}
                  >
                    Level {level}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="l-categories__header category-header">
            <h4 className="l-categories__title category-title">{this.state.selectedCategory.name}</h4>
            <p className="category-subtitle">{this.state.selectedCategory.description}</p>
          </div>

          {
            this.state.selectedCategory.warning
              ? <div className="l-categories__description">
                  <div className="alert alert--warning">{this.state.selectedCategory.warning}</div>
                </div>
              : ''
          }

          {this.getKPIs().map((kpi, index) => (
            <div className="l-categories__description" key={index}>
              <h5 className="l-categories__section-title category-section-title">{kpi.summary}</h5>
              <div className="markdown-body">
                <Markdown input={kpi.description} />
              </div>
            </div>
          ))}

          <footer className="l-categories__footer">
            <span className="copyright">Copyright &copy; Opia {new Date().getFullYear()}</span>
          </footer>
        </div>
      </div>
    </section>
  );
}

CategoriesPanel.propTypes = {
  selectedLevels: PropTypes.object.isRequired,
  categories: PropTypes.arrayOf(Object).isRequired,
  selectCategoryLevel: PropTypes.func
};

export default CategoriesPanel;
