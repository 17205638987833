import React from 'react';
import PropTypes from 'prop-types';

class PersonPanel extends React.Component {
  constructor(props) {
    super(props);
    this.dispatchUpdateName = props.updateName;
  }

  updateName = name => {
    const newName = window.prompt('choose a name', name);
    if (newName && newName.trim().length > 0)
      this.dispatchUpdateName(newName);
  };

  render = () => (
    <section className="person">
      <h3 className="person__title">
        {this.props.role ? <p className="role">{this.props.role.name}</p> : ''}
      </h3>
    </section>
  );
}

PersonPanel.propTypes = {
  name: PropTypes.string,
  role: PropTypes.object,
  tags: PropTypes.arrayOf(String),
  updateName: PropTypes.func,
};

export default PersonPanel;
